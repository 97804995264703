<!--地址管理-新增/编辑 -->
<template>
  <span>
    <slot>
      <el-button @click="show" type="text" class="text__btn">修改信息</el-button>
    </slot>
    <el-dialog
      :before-close="handleClose"
      :title="name"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      width="560px"
    >
      <p class="text__p">
        海关规定，<span class="red">购买人身份证号码和姓名相匹配</span>，否则无法通过海关安全检查。
      </p>
      <p class="text__p">例如，您使用“张三”的名字，用其他人的身份证号码，则无法通过海关检查。</p>
      <el-form :model="form" :rules="rules" ref="ruleForm" v-loading="loading" class="form">
        <el-form-item :label-width="formLabelWidth" label="支付人姓名" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="支付人身份证号码" prop="idcardNo">
          <el-input v-model="form.idcardNo"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog__footer" slot="footer">
        <el-button @click="cancel" class="dialog__footer--btn">取 消</el-button>
        <el-button :loading="saveLoading" @click="submit('ruleForm')" type="primary"
          >确 定</el-button
        >
      </div>
      <p class="text__p">
        商家承诺身份信息仅用于办理跨境商品的清关手续，不做其他用途使用，其他任何人无权查看
      </p>
    </el-dialog>
  </span>
</template>

<script>
import { create, update, get, updateCertification } from '@/api/memberIdcard';
import { validateIdCard } from '@/common/validator';
export default {
  name: 'MemberManagement',
  props: {
    id: String, // afresh-edit 重新认证, edit 编辑
  },
  data() {
    return {
      dialogFormVisible: false,
      name: '新增实名认证信息',
      value: null,
      form: {
        name: '',
        idcardNo: '',
        idcardSource: 'SHOPPING_GLOBAL',
      },
      saveLoading: false,
      loading: false,
      rules: {
        name: [{ required: true, message: '必填信息', trigger: 'blur' }],
        idcardNo: [
          { required: true, message: '必填信息', trigger: 'blur' },
          { validator: validateIdCard, trigger: 'blur' },
        ],
      },
      formLabelWidth: '140px',
      orderId: '', // 订单id
    };
  },
  methods: {
    show(id) {
      if (id) {
        this.orderId = id;
      }
      this.dialogFormVisible = true;
      this.initData();
    },
    cancel() {
      this.handleClose();
    },
    handleClose() {
      this.form = {
        name: '',
        idcardNo: '',
        idcardSource: 'SHOPPING_GLOBAL',
      };
      this.dialogFormVisible = false;
    },
    initData() {
      if (this.id) {
        this.name = this.id === 'afresh-edit' ? '重新认证实名信息' : '修改实名认证信息';
        this.fetchData();
      }
    },
    fetchData() {
      this.loading = true;
      return get()
        .then((response) => {
          this.form = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit(form) {
      const value = this.value || [];
      const [provinceId, cityId, zoneId] = value;
      Object.assign(
        this.form,
        provinceId && { provinceId },
        cityId && { cityId },
        zoneId && { zoneId }
      );
      this.listLoading = true;
      this.$refs[form].validate((valid) => {
        if (!valid) {
          return false;
        }
        let data = { ...this.form };

        let request = this.id ? update : create;
        if (this.id === 'afresh-edit') {
          data = {
            idcardNo: this.form.idcardNo,
            name: this.form.name,
            orderId: this.orderId,
          };
          request = updateCertification;
        }
        this.saveLoading = true;
        request(data)
          .then((response) => {
            this.$message.success('操作成功');
            this.$emit('sureRefresh');
            this.handleClose();
          })
          .finally(() => {
            this.saveLoading = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__title {
  font-size: 16px;
  color: #222;
  line-height: 22px;
}

/deep/ .el-form-item__label {
  font-size: 12px;
  color: #333;
}
/deep/ .el-input--medium .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.codeurl-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.code {
  width: 100px;
  height: 100px;
}
/deep/.el-cascader {
  width: 100%;
}
.red {
  color: red;
}
.text__btn {
  color: #2d89da;
  font-size: 12px;
}
.text__p {
  font-size: 12px;
  text-align: center;
}
.form {
  width: 400px;
  margin-top: 20px;
}
.dialog__footer {
  text-align: center;
  &--btn {
    margin-right: 10px;
  }
}
</style>
