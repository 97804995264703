<template>
  <div class="delivery">
    <div @click="check">
      <slot>
        <span class="btn">查看物流</span>
      </slot>
    </div>
    <!-- 物流信息弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      @close="closeDialog"
      append-to-body
      title="查看物流"
      width="700px"
      top="5vh"
    >
      <el-tabs type="card" v-model="activeName">
        <div class="empty" v-if="detailList.length === 0">暂无物流信息</div>
        <el-tab-pane
          :key="i"
          :label="`包裹${i + 1}`"
          :name="`${i}`"
          v-else
          v-for="(item, i) in detailList"
        >
          <div class="detail">
            <div class="line">
              <div
                class="goods-detail"
                v-for="(commodity, commodityIndex) in item.commodities"
                :key="commodityIndex"
              >
                <div class="goods-detail--img">
                  <img :src="commodity.imgUrl || 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/mobile-terminal/default-image.png'" />
                  <span class="goods-detail--quantity">实发{{ commodity.quantity }}件</span>
                </div>
                <div class="goods-detail--view">
                  <div class="goods-detail--title commo-ellipsis">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="commodity.commodityName"
                      placement="top"
                    >
                      <span> {{ commodity.commodityName }}</span>
                    </el-tooltip>
                  </div>
                  <div class="goods-detail--specification" v-if="commodity.commoditySpecValue">规格:{{ commodity.commoditySpecValue }}</div>
                </div>
              </div>
            </div>
            <div class="line">
              <div class="line">
                <div class="label delivery-label">{{ item.companyName }}：</div>
                <div class="info">{{ item.deliveryNo || '暂无' }}</div>
              </div>
              <div class="line">
                <div class="label delivery-label">发货时间：</div>
                <div class="info">
                  {{ item.deliveryTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
                </div>
              </div>
            </div>
            <div class="delivery-detail">
              <div class="tips" v-if="!item.deliveryItems || item.deliveryItems.length === 0">
                暂无物流信息
              </div>
              <div v-else>
                <!-- 有物流信息 -->
                <el-timeline>
                  <el-timeline-item
                    v-for="(detail, detailIdx) in item.deliveryItems"
                    :key="detailIdx"
                    :timestamp="detail.time"
                    :class="detailIdx === 0 ? 'animation-icon' : ''"
                  >
                    {{ detail.context }}
                  </el-timeline-item>
                </el-timeline>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span class="dialog-footer" slot="footer">
        <el-button @click="dialogVisible = false" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getDeliveryDetail } from '@/api/order/inquiry/list';
import { getDeliveryByRefundOrderId } from '@/api/order/refund/index';
export default {
  name: 'FetchDeliveryDetails',
  computed: {},
  data() {
    return {
      dialogVisible: false,
      activeName: 0,
      detailList: [], // 物流列表 有可能有多个包裹信息
    };
  },
  watch: {},
  mounted() {},
  methods: {
    async check() {
      this.dialogVisible = true;
      const request = this.type === 'refund' ? getDeliveryByRefundOrderId : getDeliveryDetail;
      const { data } = await request(this.id);
      this.detailList = [...data];
    },
    closeDialog() {
      this.deliveryDetail = {
        companyCode: '', // 快递公司编码
        companyName: '', // 快递公司
        deliveryNo: '', // 快递单号
        delivery: [], // 物流详情
      };
    },
  },
  props: {
    id: String,
    type: String // 退款单的type为refund
  },
};
</script>
<style lang="scss" scoped>
@import './styles.scss';
</style>