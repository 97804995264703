import { withExtTenantIdRequest } from '@/utils/request';

// 新增实名认证信息
export function create(data) {
  return withExtTenantIdRequest({
    url: '/shopmember/api/memberIdcard/create',
    method: 'post',
    data
  });
}

// 修改实名认证信息
export function update(data) {
  return withExtTenantIdRequest({
    url: '/shopmember/api/memberIdcard/updateIdcardInfo',
    method: 'post',
    data
  });
}

// 获取实名认证信息
export function get() {
  return withExtTenantIdRequest({
    url: `/shopmember/api/memberIdcard/get`,
    method: 'get'
  });
}

// 报关失败重新认证
export function updateCertification(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantOrder/updateCertificationAndReDeclare',
    method: 'post',
    data
  });
}
