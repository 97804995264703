import request, { withExtTenantIdRequest } from '@/utils/request';

// 获取全部的订单信息
export function list (data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantOrder/listMyPage',
    method: 'post',
    data
  });
}
// 获取全部的包裹信息
export function getDeliveries (id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/merchantOrder/getDeliveries?id=${id}`,
    method: 'get'
  });
}
// 获取订单详情
export function getById (id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/merchantOrder/getOrderDetailVO?id=${id}`,
    method: 'get'
  });
}
// 取消订单
export function cancel (id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/merchantOrder/cancel?id=${id}`,
    method: 'post'
  });
}

// 获取交易快照
export function getSnapshootById (id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/order/getSnapshootByOrderDetailId?id=${id}`,
    method: 'get'
  });
}

// 获取创建方式
export function fetchTypeOptions () {
  return withExtTenantIdRequest({
    url: '/common/api/dict/listByType?type=syzg_order_sale_type',
    method: 'get'
  });
}
// 获取订单状态
export function fetchOrderStatusOptions () {
  return withExtTenantIdRequest({
    url: '/common/api/dict/listByType?type=syzg_order_status',
    method: 'get'
  });
}

// 获取物流公司列表
export function fetchLogisticsOptions (data) {
  return request({
    url: '/soyoungzg/api/orderDelivery/listAllDeliveryCompany',
    method: 'post',
    data
  });
}

// 手工发货
export function deliveryGoodsByHand (data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/order/delivered',
    method: 'post',
    data
  });
}

// 退款
export function refund (data) {
  return withExtTenantIdRequest({
    url: '/pay/api/weChatPay/refund',
    method: 'post',
    data
  });
}

// 订单添加客服备注
export function addServiceNotes (data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/order/addServiceNotes',
    method: 'post',
    data
  });
}

// 订单改价，保存订单详情商品价格信息及修改订单实付总价
export function updateOrderDetailPrice (data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/order/updateOrderDetailPrice',
    method: 'post',
    data
  });
}

// 订单列表导出
export function exportTable (data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/merchantOrder/orderExport`,
    method: 'post',
    responseType: 'arraybuffer',
    data,
    timeout: 30 * 1000
  });
}
// 订单列表代发模板导出
export function exportDropshipping (data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/merchantOrder/exportDropshipping`,
    method: 'post',
    responseType: 'arraybuffer',
    data,
    timeout: 30 * 1000
  });
}

// 获取订单来源
export function fetchOrderSourceOptions () {
  return withExtTenantIdRequest({
    url: '/common/api/dict/listByType?type=syzg_merchant_order_source',
    method: 'get'
  });
}
// 获取订单类型
export function fetchOrderTypeOptions () {
  return withExtTenantIdRequest({
    url: '/common/api/dict/listByType?type=order_type',
    method: 'get'
  });
}

// 获取物流详情
export function getDeliveryDetail (id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/merchantOrder/listDeliveryItemsByOrderId?id=${id}`,
    method: 'get'
  });
}
// 批量加购
export function addToCartInBatch (data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantShoppingCart/addToCartInBatch',
    method: 'post',
    data
  });
}

// 确认收货
export function received (data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantOrder/received',
    method: 'post',
    data
  });
}

// 获取订单列表合同主体列表
export function listOrderContractInfo (data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContractInfo/listOrderContractInfo',
    method: 'post',
    data
  });
}
